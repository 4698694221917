.banner{
    height: 60vh;
    width: 100%;
    background-image: url("../../../assets/Image/primeStatus-banner.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    
}

// responsive==============================================
@media  screen and (max-width:767px) {
    .banner{
        height: 25vh;
    }
}

@media screen and (min-width: 768px) and (max-width: 992px) {

    .banner{
        height: 40vh;
    }
}

@media screen and (min-width: 993px) and (max-width: 1200px) {
    .banner{
        height: 60vh;
    }
}

// @media screen and (min-width: 1201px) and (max-width: 1599px) {
//     .banner{
//         height: 50vh;
//     }
// }
