.stunning-section{
    width: 100%;
    height: 120vh;
    border-color: #FFFFFF;
    display: flex;
    &-left{
        width: 10%;
        height: 100%;
        padding: 15rem 0;
        
        div{
            width: 100%;
            height: 80%;
            background-image: url("../../../assets/Image/about-pattern2.png");
            background-size: contain;
            // background-position: center;
            background-repeat: no-repeat;
        }
    
    }

    &-middle{
        padding: 13rem 3rem;
        width: 80%;
        // padding: 0 3rem;
        height: 100%;
        display: flex;
        flex-direction: column;
        h1{
            padding: 0 5rem 2rem ;
            color: rgb(77, 115, 116);
            text-align: center;
        }

        &-container{
            padding: 1rem 0;
            display: flex;
            height: 100%;
            
            div{
                width: 35%;
                display: flex;
                // height: 10%;
                padding: 0 2rem;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                h1{
                    font-size: 2.6rem;
                    font-weight: 400;
                    color: rgb(8,40,49);
                    text-align: center;
                }
                span{
                    padding: 2rem 0;
                    width: 25%;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
           
        }
    }
    
    &-right{
        width: 10%;
        height: 100%;
        padding: 15rem 0;
        div{
            width: 100%;
            height: 80%;
            background-image: url("../../../assets/Image/pattern-project.png");
            background-size: contain;
            background-position: right;
            background-repeat: no-repeat;
        }
    }
}

// responsive==============================================

// @media screen and (max-width: 426px) {
//     .stunning-section{
//         height: 50vh;
//     }
// }
@media  screen and (max-width:767px) {
    .stunning-section{
        height: 40vh;
    }
    .stunning-section-middle-container div h1 {
        font-size: 3rem;
    }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
    .stunning-section{
        height: 70vh;
    }
}

@media screen and (min-width: 993px) and (max-width: 1200px) {
    .stunning-section{
        height: 90vh;
    }
}

// @media screen and (min-width: 1201px) and (max-width: 1599px) {
//     .stunning-section{
//         height: 70vh;
//     }
// }

