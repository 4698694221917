.logo-phil {
    width: 100%;
    display: flex;
    height: 70vh;
    &-left {
        padding-left: 15rem;
        background: #4d7374;
        width: 65%;
        // align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;

        .logo-phil-title {
            margin-bottom: 60px;
            h2 {
                font-size: 7.5rem;
                font-weight: 400;
                text-transform: uppercase;
                color: #fff;
            }
        }

        .content-wrapper {
            .logo{
                max-width: 50px;
                margin-bottom: 30px;
                img{
                    width: 100%;
                }
            }

            .text {
                width: 80%;
                p{
                    font-size: 2rem;
                    color: #fff;
                }
            }
        }
    }

    &-right {
        width: 35%;
        padding-right: 15rem;
        // width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        padding-left: 50px;
        .logo-phil-title{
            margin-bottom: 60px;
            color: #fff;

            -webkit-user-select: none; /* Safari */        
            -moz-user-select: none; /* Firefox */
            -ms-user-select: none; /* IE10+/Edge */
            user-select: none;

            h2 {
                font-size: 7.5rem;
                font-weight: 400;
                text-transform: uppercase;
            }

            &::selection {
                background-color: #fff;
            }
        }

        &-content {
            height: 100%;
            display: flex;
            // align-items: center;
            justify-content: center;
            flex-direction: column;
        }

        .black-logo{
            max-width: 200px;
            margin-bottom: 30px;
            img{
                width: 100%;
            }
        }

        .black-logo-content{
            max-width: 80%;
            p{
                font-size: 2rem;
                color: #000;
            }
        }
    }
}