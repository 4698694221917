.overlay-video {
    position: fixed;
    z-index: 10000;
    width: 100%;
    height: 100vh;
    background-color: #0E1922;

    video {
        width: 100%;
        height: 100vh;
    }
}