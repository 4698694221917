.home-project {
    // height: 100vh;
    padding: 20rem 0;
    display: flex;
    align-items: center;
    // min-height: 80vh;

    .custom-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &-title{
        padding-bottom: 4rem;
        
        h1 span {
            // opacity: 0;
        }
    }

    &-content {
        padding: 2rem 9rem;
        display: flex;

        a {
            span{
                transition: all 0.2s ease;
            }
            &:hover {
                color: #0d1823;
                opacity: 1;
                span {
                    transition: all 0.2s ease;
                    margin-left: 7px;
                }
            }
        }

        &-1 {
            // border-left: 1px solid grey;
            // border-right: 1px solid grey;
            overflow: hidden;
            position: relative;

            &::before{
                content: '';
                position: absolute;
                left: 0;
                width: 1px;
                height: 100%;
                background-color: #0d1823;
                top: 0;
                transform-origin: top top;
            }

            &::after{
                content: '';
                position: absolute;
                right: 0;
                width: 1px;
                height: 100%;
                background-color: #0d1823;
                top: 0;
                transform-origin: top;
            }
        }

        &-2{
            position: relative;
            overflow: hidden;
            // border-right: 1px solid grey;

            // &::before{
            //     content: '';
            //     position: absolute;
            //     left: 0;
            //     width: 1px;
            //     height: 100%;
            //     background-color: #0d1823;
            // }

            &::after{
                content: '';
                position: absolute;
                right: 0;
                width: 1px;
                height: 100%;
                top: 0;
                background-color: #0d1823;
                transform-origin: top ;
            }
        }
        &-1,
        &-2 {
            width: 50%;
            // height: 40vh;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0rem 3rem;
            justify-content: space-around;
            

            div {
                width: 100%;
            //   height: 50%;
                position: relative;
                overflow: hidden;

            .trans-img{
                width: 100%;
                height: 100%;
                background-color:#fff;
                position: absolute;
                z-index: 5;
            }

            .logo-img {
                background-color: #0d1823;
                text-align: center;
                position: absolute;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-content: center;
                align-items: center;
                opacity: 0;
                transition: all 0.2s ease;
                z-index: 10;

                cursor: pointer;
                &:hover {
                    opacity: 1;
                    transition: all 0.2s ease;
                }

                img{

                    max-width: 150px;
                    max-height: 100px;
                    // height: 100%;
                    // // object-fit: cover;
                    // margin: auto;
                }
            }
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    // object-position: center;
                }
            }
            p {
                font-size: 1.5rem;
                padding-bottom: 0;
                margin-top: 15px;
                margin-bottom: 12px;
                color: #0d1823;
                font-weight: 400;
            }
            h3 {
                text-align: left;
                font-weight: 500;
                padding-right: 25rem;
                font-size: 3rem;
                margin-bottom: 15px;
                color: #0d1823;
                font-weight: 400;
            }
            a {
                color: #0d1823;
                text-decoration: none;
                font-size: 2rem;
                font-weight: 0;
                letter-spacing: 0.6px;
                text-align: left;
            }
        }
        .home-project-content-1 {
            padding: 0 11.5rem 0 7rem;
        }
        .home-project-content-2 {
            padding: 0 7rem 0 11.5rem;
        }   
    }
}
// responsive==============================================
@media  screen and (max-width:767px) {

    .react-multi-carousel-dot-list {
        bottom: 10px;
    }
    
    .home-project-content-1::before , .home-project-content-1::after, .home-project-content-2::after{
        display: none!important;
    }

    .home-project {
        height: auto;
        // padding: 20rem 0;
        .custom-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }

    .home-project-content .home-project-content-2, .home-project-content .home-project-content-1 {
        padding: 5rem;
    }
       .home-project-content{
        flex-direction: column;
        padding: 0px;
        // padding: 5rem 5rem 5rem 5rem;

        &-1 {
            margin-bottom: 40px;
        }
        &-1,&-2{
            width: 100%;
            // padding: 5rem 5rem 5rem 5rem;
            // height: 25vh;

            p{
                font-size: 6rem;
                margin-top: 15px;
                margin-bottom: 5px;
            }
            h3{
                // margin-top: 5px;
                margin-bottom: 5px;
                font-size: 9rem;
                padding-right: 0px;
            }
            a{
                font-size: 6rem;
                // margin-bottom: 10px;
            }
        }
       }

       .home-project-content-1 {
        border: 0px
       }
       .home-project-content .home-project-content-2, .home-project-content .home-project-content-1 {
        padding: 0px;
       }

       .home-project-content-2 {
        border: 0px
       }
    
}

@media screen and (min-width: 768px) and (max-width: 992px) {

    .home-project-content-1 p, .home-project-content-2 p {
        font-size: 2.8rem;
        margin-top: 15px;
        margin-bottom: 5px;
    }

    
    .home-project-content-1 h3, .home-project-content-2 h3{
        font-size: 4.5rem;
        padding-right: 0px;
        margin-bottom: 10px;
    }

    .home-project-content-1 a, .home-project-content-2 a{
        font-size: 2.8rem;
    }

    .home-project-content{
        padding: 0px;
    }
}

@media screen and (min-width: 993px) and (max-width: 1200px) {
    .home-project {
            // height: 80vh;
    }

       .home-project-content{
        &-1,&-2{
        }
       }
}

