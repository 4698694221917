

.abt-hero-section {
    background-image: url("../../../assets/Image/about-hero.png");
    height: 90vh;
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    width: 100%;
    display: flex;
    position: relative;
    // padding: 0 15rem;

    &-left {
        width: 50%;
        height: 100%;
        display: flex;
    }
    &-right {
        position: absolute;
        right: 15rem;
        bottom: 10%;
        // width: 50%;
        // height: 100%;
        display: flex;
        flex-direction: column;
        padding-top: 10rem;
        justify-content: center;
        align-items: center;

        h1 {
            font-size: 12rem;
            color: #fff;
            width: 100%;
            font-weight: 400;
            margin-bottom: 20px;

            .cercle {
                display: inline-block;
                width: 40px;
                img {
                    width: 100%;
                    // height: 19%;
                    padding-bottom: 1rem;
                }
            }
        }
    }
}


@media screen and (max-width: 767px){
    // .abt-hero-section{
    //     height: 40vh;
    // }

    .abt-hero-section-right h1 span{
        width: 25px;
    }

    .abt-hero-section-right h1{
        font-size: 25rem;
    }
}

@media screen and (min-width: 768px) and (max-width: 992px){
    .abt-hero-section{
        // height: 60vh;
    }

    .abt-hero-section-right h1 span{
        width: 40px;
    }

    .abt-hero-section-right h1{
        font-size: 17rem;
    } 

}

@media screen and (min-width: 993px) and (max-width: 1200px){
    .abt-hero-section{
        // height: 80vh;
    }
    
}

// @media screen and (min-width: 1201px) and (max-width: 1599px){
//     .abt-hero-section{
//         height: 80vh;
//     }
    
// }