.cnt-hero-section{
    background-image: url("../../../assets/Image/contact-hero.jpg");
    height: 90vh;
    background-size: cover;
    background-position: right top;
    background-repeat: no-repeat;
    width: 100%;
    display: flex;
}

// responsive==============================================
@media screen and (max-width: 767px) {
    .cnt-hero-section{
        height:50vh;
        background-size: cover;
        background-position: center;
    }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
    .cnt-hero-section{
        height:60vh;
    }
}

@media screen and (min-width: 993px) and (max-width: 1200px) {
    .cnt-hero-section{
        height:80vh;
    }
}

// @media screen and (min-width: 1201px) and (max-width: 1599px) {
//     .cnt-hero-section{
//         height:70vh;
//     }
// }
