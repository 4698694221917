.success{
    background-color: rgb(224, 225, 226);
    // height: 70vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 10rem 0rem;
    margin: 0;
    position: relative;
    overflow: hidden;
    z-index: 1;

    .container-custom{
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        h1{
            padding-bottom: 5rem;
        }
    }

    &-content {
        display: flex;
        // height: 25vh;
        padding: 0 4rem;
        justify-content: center;
        align-items: center;
        width: 100%;

        div {
            width: 25%;
            // height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            border-right: 2px solid rgb(77, 115, 116);
            justify-content: center;
            padding: 5rem 0;

            span {
                font-size: 3rem;
                font-weight: 0;
                opacity: 0.6;
                text-align: left;
                color: rgb(77, 115, 116);
                text-transform: uppercase;
                line-height: 1.5;
            }

            h4 {
                font-size: 3rem;
                font-weight: 400;
                color: rgb(77, 115, 116);
                text-transform: uppercase;
                line-height: 1.5;
                font-family: sans-serif;
            }
        }
        #last {
            border: none;
        }
    }

}

// responsive==============================================
@media screen and (max-width: 767px) {

    .success{
        height: auto;
        padding: 20rem 0;

        &-content{
            flex-direction: column;
            height: auto;
            div{
                width: 100%;
                height: auto;
                padding: 5rem 0;
                border-right:none;
                span{
                    font-size: 5rem;
                }
                h4{
                    font-size: 5rem;
                }
            }
        } 
        
    }

    .success .container-custom h1{
        text-align: center;
    }
    
}

@media screen and (min-width: 768px) and (max-width: 992px) {

    .success{
        // height: 40vh;

        &-content{
            div{
                width: 25%;
                height: auto;
                padding: 30px 0;
                display: flex;
                flex-direction: column;
                align-items: center;
                border-right: 2px solid rgb(77, 115, 116);
                justify-content: center;
            }
        } 
        
    }
}

@media screen and (min-width: 993px) and (max-width: 1200px) {
    .success{
        // height: 65vh;
    }
}

// @media screen and (min-width: 1201px) and (max-width: 1599px) {
//     .success{
//         height: 45vh;
        
//     }
// }
