.pe {
    &-wrapper{
        display: flex;
        flex-direction: row;
        .pe-left{
            width: 50%;
            background: #4d7374;
            padding-right: 80px;
            padding-top: 60px;
            display: flex;
            flex-direction: column;
            img{
                width: 100%;
            }
            h1{
                color: #fff;
                text-transform: uppercase;
                font-size: 6rem;
                text-align: right;
            }
        }

        .pe-right{
            width: 50%;
            position: relative;
            .pe-content{
                position: absolute;
                transform: translate(-50%, -50%);
                left: 50%;
                top: 50%;
                display: flex;
                flex-direction: column;
                &-top{
                    display: flex;
                    flex-direction: row;
                    border-bottom: 1px solid rgba(77,115,116, 0.2);
                    // padding-top: 3rem;
                    &-left{
                        width: 250px;
                        text-align: center;
                        padding-top: 3rem;
                        padding-bottom: 3rem;
                        // padding-right: 3rem;

                        h2{
                            font-size: 6rem;
                            color: #4d7374;
                        }
                        p{
                            color: #4d7374;
                            font-size: 2rem;
                            margin-bottom: 0;
                        }
                    }

                    &-right{
                        padding-top: 3rem;
                        padding-bottom: 3rem;
                        width: 250px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        border-left: 1px solid rgba(77,115,116, 0.2);

                        h2{
                            font-size: 6rem;
                            color: #4d7374;
                        }
                        p{
                            color: #4d7374;
                            font-size: 2rem;
                            margin-bottom: 0;
                        }
                        
                    }
                }

                &-bottom{
                    display: flex;
                    flex-direction: row;
                    
                    // padding-bottom: 3rem;
                    &-left{
                        width: 250px;
                        padding-top: 3rem;
                        padding-bottom: 3rem;
                        // text-align: center;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        h2{
                            font-size: 6rem;
                            color: #4d7374;
                            line-height: 1;
                            margin-bottom: 0;
                        }
                        p{
                            color: #4d7374;
                            font-size: 2rem;
                            margin-bottom: 0;
                        }
                        // padding-right: 3rem;
                        
                    }

                    &-right{
                        // padding-left: 2rem;
                        width: 250px;
                        padding-top: 3rem;
                        padding-bottom: 3rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        border-left: 1px solid rgba(77,115,116, 0.2);
                        h2{
                            font-size: 6rem;
                            color: #4d7374;
                        }
                        p{
                            color: #4d7374;
                            font-size: 2rem;
                            margin-bottom: 0;
                        }
                        
                    }
                }
            }
        }
    }
}

@media (max-width: 600px) {
    .pe-wrapper .pe-right .pe-content-top-left p,
    .pe-wrapper .pe-right .pe-content-top-right p,
    .pe-wrapper .pe-right .pe-content-bottom-left p,
    .pe-wrapper .pe-right .pe-content-bottom-right p {font-size: 4rem;}
}