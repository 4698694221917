.Prime-developer {
    background-color: rgb(224, 225, 226);
    // height: 60vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    position: relative;
    overflow: hidden;
    z-index: 1;

    .container-custom {
        display: flex;
        padding-right: 0;
        align-items: center;
    }

    &-left {
        width: 75%;
        // height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        h1 {
            text-align: left;
            padding-bottom: 2rem;
            line-height: 1.5;
        }
        p {
            font-size: 4rem;
            padding: 0 29rem 0 18rem;
            letter-spacing: normal;
            line-height: 1.2;
            margin: 0;
            font-weight: 500;
            text-align: left;
        }
    }

    &-right {
        height: 60vh;
        background-color: rgb(168, 168, 168);
        background-image: url("../../../assets/Image/about-pattern.png");
        background-repeat: no-repeat;
        background-size: cover;
        width: 25%;
    }
}

// responsive==============================================
@media screen and (max-width: 767px) {
    .Prime-developer{

        height: auto;
        padding: 20rem 0;

        &-left{
            width:100%;
        }
        &-right{
            display: none;
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
    .Prime-developer{
        height: 30vh;
    }
}

@media screen and (min-width: 993px) and (max-width: 1200px) {
    .Prime-developer{
        height: 45vh;
    }
}

// @media screen and (min-width: 1201px) and (max-width: 1599px) {
//     .Prime-developer{

//         height: 45vh;
//     }
// }
