.images-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .image-one {
        width: 49%;
        height: 50vh;
        img{
            height: 100%;
            object-fit: cover;
            width: 100%;
        }
    }

    .image-two {
        width: 49%;
        height: 50vh;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}