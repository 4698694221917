@import "react-alice-carousel/lib/scss/alice-carousel.scss";

.testimonial{
    background-color: rgb(255, 255, 255);
    // height: 80vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;
    position: relative;
    overflow: hidden;
    z-index: 1;
    margin: 20rem 0;

    .container-custom   {
        display: flex;
        flex-direction: column;
        
    }

    .alice-carousel__prev-btn {
        display: none;
    }

    .alice-carousel__next-btn
    {
        display: none;
    }

    &-content {
        display: flex;
        width: 100%;
        padding-top: 3rem;
        &-left,
        &-right {
            width: 100%;
            height: auto;
            display: flex;
            align-items: flex-start;
            padding: 6rem 1rem;
            justify-content: center;

            span {
                width: 13%;
                margin-right: 3rem;
                padding: 0 1rem;
                img {
                    width: 100%;
                    height: auto;
                }
            }
            div {
                width: 77%;
                padding-top: 5rem;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding-bottom: 1rem;

                p {
                    font-size: 1.6rem;
                    padding-bottom: 0;
                    font-weight: 550;
                    margin: 0;
                    color: #0d1823;
                }
                h3 {
                    text-align: left;
                    padding-bottom: 2rem;
                    margin: 0;
                    font-size: 2.3rem;
                    font-weight: 400;
                    color: #0d1823;
                }

            }
        }

    }
    .testimonial-content-left {
        padding-right: 6rem;
    }

    .testimonial-content-right {
        // border-left: 1px solid grey;
        padding-right: 1rem;
        padding-left: 4rem;
    }
}

// responsive==============================================
@media  screen and (max-width:767px) {
.testimonial{
    // height: 45vh;

    &-content{
        flex-direction: column;
        width: 100%;

        &-left,&-right{
            width: 100%;
            border: none;
           div {
            h3{
                font-size: 5.5rem;
            }
            p{
                font-size: 3.5rem;
            }
           }
        }

    }

    .testimonial-content-left span, .testimonial-content-right span {
        margin-right: 5rem;
    }
    .testimonial-content-right {
        border: none;
        padding: 0;
    }

}
}

@media screen and (min-width: 769px) and (max-width: 992px) {

    .testimonial{
        // height: 45vh;
    
    }
}

@media screen and (min-width: 993px) and (max-width: 1200px) {
    .testimonial{
        // height: 60vh;
    
    }
}

// @media screen and (min-width: 1201px) and (max-width: 1599px) {
//     .testimonial{
//         height: 45vh;
    
//     }
// }
