.pr {
    background: #4d7374;
    position: relative;
    height: 30vh;
    .left-img{
        left: 0;
        position: absolute;
        max-width: 50px;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .right-img{
        right: 0;
        max-width: 50px;
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
    }

    &-content {
        display: flex;
        width: 100%;
        align-items: center;
        height: 30vh;
        .pr-left {
            width: 40%;
            padding: 40px 30px;
            text-align: center;
            border-right: 1px solid rgba(255,255,255,.6);
            h1 {
                font-size: 6rem;
                color: #fff;
                text-transform: uppercase;
            }
        }

        .pr-right{
            width: 60%;
            padding: 0 30px;
            p {
                color: white;
                font-size: 2.4rem;
            }
        }
    }
}

@media (max-width:600px) {
   .pr-content .pr-right p { font-size: 5.5rem };
}