.pa {
    width: 100%;
    min-height: 100vh;
    &-wrapper {
        width: 100%;
        display: flex;
    }

    &-left {
        width: 30%;
        padding: 5rem  40px 5rem 15rem;
        height: 100vh;
        h1 {
            font-size: 6rem;
            text-transform: uppercase;
            color: #4d7374;
            padding-bottom: 20px;
        }

        ul {
            margin: 0px;
            padding-left: 0px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-bottom: 8rem;
            // height: 100%;
            height: 80%;
            li {
                font-size: 2.2rem;
                // margin-bottom: 20px;
            }
        }
    }

    &-right {
        background-color: #eaeaea;
        width: 70%;
        height: 100vh;
        padding: 0rem 0 0 0;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        h1 {
            padding-left: 15rem;
            font-size: 6rem;
            text-transform: uppercase;
            color: #4d7374;
        }
        .slide-one, .slide-two {
            width: 100%;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}

@media (max-width: 600px) {
    .pa-left ul li {font-size: 5.5rem;}
    .pa-left h1 {font-size: 7rem;}
}