.home-about {
    background-image: url("../../../assets/Image/homeAboutBg.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    
    // height: 50vh;
    padding: 20rem 0;
    width: 100%;
    // padding: 0 14rem;
    // margin: 0;
    position: relative;
    overflow: hidden;
    z-index: 1;

    .container-custom{
        display: flex;
        height: 100%;
        align-items: center;
    }

    .hover-link{
        transition: all 0.2s ease;
        position: relative;
        span{
            transition: all 0.2s ease;
            width: 10px;
        }

        &:hover{
            color: #fff;
            transition: all 0.2s ease;
            span {
                margin-left: 7px;
                transition: all 0.2s ease;
            }
        }
    }

    &-left {
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        // align-items: center;

        h1 {
            font-size: 14rem;
            color: rgb(237, 231, 231);
            font-weight: 400;
            text-transform: uppercase;
            letter-spacing: 2px;
            line-height: 1;
        }
    }
    &-right {
        width: 50%;
        height: 100%;
        display: flex;
        padding-left: 6rem;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        h3 {
            font-size: 4.5rem;
            font-weight: 300;
            line-height: 1.2;
            color: rgb(253, 253, 253);
            text-align: left;
            padding-bottom: 2rem;
        }
        a {
            font-size: 2rem;
            color: rgb(255, 255, 255);
            text-decoration: none;
        }
    }
}


// responsive==============================================
@media  screen and (max-width:767px) {

    .home-about {
        // height: 40vh;
        padding: 20rem 0;
        .container-custom{
            
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h1{
            font-size: 28rem;
        }
        h3{
            font-size: 6.5rem;
        }
        a{
            font-size: 4.5rem;
        }
        

        div{
            width: 100%;
            // height: 50%;
            text-align: center;
            margin-bottom: 10px;
            
        }

        .home-about-right {
            padding: 0px;
        }
        }
    }
    
}

@media screen and (min-width: 768px) and (max-width: 992px) {
    .home-about {
        // height: 40vh;
        padding: 10rem 0;
    }
}

@media screen and (min-width: 993px) and (max-width: 1200px) {
    .home-about {
        // height: 50vh;
    }
}

// @media screen and (min-width: 1201px) and (max-width: 1599px) {
//     .home-about {
//         height: 40vh;
//     }
// }
