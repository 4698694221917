@font-face {
    font-family: assreg;
    src: url('./assets/Fonts/Altersan-Regular.ttf');
}

@font-face {
    font-family: asslight;
    src: url('./assets/Fonts/Altersan-Light.ttf');
}

html {
    font-size: calc(100vw/1920 * 10);
    // font-family: sans-serif;
    font-family: 'assreg'!important;
}

body {
    font-family: 'assreg'!important;
    overflow-x: hidden;
}

h1, h2, h3, h4, h4, h6, p, div, span, a {
    font-family: 'assreg'!important;

}

*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}
a {
    text-decoration: none!important;
    outline: none;
}

a:active, a:focus {
    outline: 0!important;
    border: none!important;
    -moz-outline-style: none;
  }