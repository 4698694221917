.gallery-section {
    width: 100%;
    max-height: 130vh;
    padding:5rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 130vh;

    &-left {
        width: 70%;
        height: 100%;
        display: flex;
        padding-right: 2rem;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;

        &-top {
            width: 70%;
            // padding: 0 24rem 7rem 15rem;
            padding: 10rem 0 3rem 15rem;
            margin-right: auto;

            h1 {
                text-align: left;
                line-height: 1.2;
                color: rgb(77, 115, 116);
            }
        }
        &-middle {
            display: flex;
            width: 100%;
            align-items: flex-end;
            justify-content: space-between;
            padding-right: 2rem;
            padding-bottom: 3rem;

            &-left {
                width: 50%;
                // height: 70%;
                background-image: url("../../../assets/Image/contact-pattern.png");
                background-size: cover;
                background-position: center;
            }
            &-right {
                width: 40%;
                // height: 100%;    
                // margin-bottom: 4rem;
                p {
                    width: 100%;
                    text-align: left;
                    // font-weight: 550;
                    padding: 0;
                    margin: 0;
                    color: rgb(9, 9, 9);
                    font-size: 2rem;
                }
            }
        }
        &-bottom {
            height: 58%;
            width: 100%;
            display: flex;
            position: relative;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
    &-right {
        // position: absolute;
        // right: 0;
        // bottom: 0;
        width: 30%;
        height: 100%;
        padding-top: 7rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &-top {
            height: 60%;
            width: 100%;
            padding-bottom: 2rem;
            position: relative;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &-bottom {
            height: 40%;
            width: 100%;
            position: relative;
            img {
                width: 100%;
                object-fit: cover;
                height: 100%;
            }
        }
    }
}

// responsive==============================================

@media  screen and (max-width:767px) {
    .gallery-section{
        // height: 120vh;
        display: flex;
        flex-direction: column;
        
        .gallery-section-left-bottom {
            // height: 50%;
        }
        .gallery-section-right-top{
            // height: 50%;
            // margin-bottom: 12rem;
        }
        .gallery-section-right-bottom{
            // height: 50%;
        }

        &-left,&-right{
            width: 100%;
            padding: 0 15rem;
            height: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            &-top{
                padding-left: 0;
                padding-bottom: 0rem;
            }
        }
        .gallery-section-left-middle-left{
            display: none;
        }
        .gallery-section-left-middle-right{
            width: 100%;
            padding-bottom: 10rem;
            
            p{
                font-size: 4.5rem;
            }
        }
    }
    
}

@media screen and (min-width: 768px) and (max-width: 992px) {
    .gallery-section{
        // height: 80vh;
    }
}

@media screen and (min-width: 993px) and (max-width: 1200px) {
    .gallery-section{
        // height: 110vh;
    }
}

// @media screen and (min-width: 1201px) and (max-width: 1599px) {
//     .gallery-section{
//         height: 100vh;

//         p{
//             font-size: 2.3rem;
//         }
//     }
// }
