.title{
    font-size: 7.5rem;
    font-weight: 400;
    text-align: left;
    color: #4d7374;;
    text-transform: uppercase;
    line-height: 1.5;
}

@media screen and (max-width: 768px) {
    .title{
        font-size: 12rem;
        font-weight: 400;
        text-align: left;
        color: #4d7374;;
        text-transform: uppercase;
        line-height: 1.5;
    }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
   
}

@media screen and (min-width: 993px) and (max-width: 1200px) {

}

@media screen and (min-width: 1201px)  {
  
}
