.ph {
    width: 100%;
    height: 100vh;
    background-image: url('../../assets//Image/praangan-hero.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;

    .overlay {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        background-color: rgba(0,0,0,.5);
    }
    // &::after {
    //     content: '';
        
    // }
    &-wrapper{
        width: 50%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 1;
        justify-content: center;
        // align-items: center;
    }

    &-logo{
        width: 70%;
        img{
            width: 100%;
        }
        margin-bottom: 30px;
        margin-top: 100px;
    }

    &-title {
        margin-bottom: 30px;
        h2 {
            font-size: 3.6rem;
            text-transform: uppercase;
            color: #fff;
        }
    }

    &-detail {
        margin-bottom: 10px;
        h2 {
            font-size: 3.6rem;
            text-transform: uppercase;
            color: #fff;
        }
    }

    &-address {
        margin-bottom: 40px;
        p{
            color: white;
            font-size: 2rem;
        }
    }

    &-walk {
        p{
            color: white;
            font-size: 2rem;
        }
    }
}

@media (max-width: 600px) {
    .ph-address p {font-size: 5rem;}
    .ph-detail h2, .ph-title h2 {font-size: 6rem;}
}