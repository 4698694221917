.coming-soon {
    background-image: url("../../../assets/Image/coming-soon.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    // height: 60vh;
    width: 100%;
    position: relative;
    overflow: hidden;
    z-index: 1;
    display: flex;
    padding: 20rem 0;

    .container-custom {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &-left {
        // width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        // padding: 0rem 4rem;
        justify-content: center;

        h1 {
            font-size: 14rem;
            line-height: 1;
            color: rgb(237, 231, 231);
            font-weight: 400;
            letter-spacing: 2px;
            // line-height: 1.5;
            text-align: left;
        }
    }
    &-right {
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        // padding: 0rem 4rem;
        justify-content: center;
        h1 {
            color: rgb(253, 254, 255);
            text-align: right;
            font-size: 17rem;
            font-weight: 400;
            line-height: 1;
            display: flex;
            flex-direction: column;
        }

        span{
            text-align: right;
            color: white;
            font-size: 2rem;
        }
    }
}

// responsive==============================================
@media screen and (max-width: 767px) {
    .coming-soon {
        padding: 20rem 0;
        // height: 30vh;

        .container-custom {
            flex-direction: column;
            align-items: center;
            justify-content: center;

            
        }
        &-left {
            width: 100%;
            h1 {
                text-align: center;
            }
        }

        &-right {
            width: 100%;
            align-items: center;
            h1 {
                text-align: center;
            }

            span{
                text-align: right;
                color: white;
                font-size: 4rem;
            }
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
    .coming-soon {
        // height: 40vh;
        padding: 10rem;
    }
}

@media screen and (min-width: 993px) and (max-width: 1200px) {
    .coming-soon {
        // height: 50vh;
    }
}

// @media screen and (min-width: 1201px) and (max-width: 1599px) {
//     .coming-soon{
//         height: 45vh;
//     }
// }
