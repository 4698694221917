.get-in-touch{
    height: 100vh;
    width: 100%;
    background-color: #86b8ae;
    display: flex;

    &-content{
        width: 60%;
        // height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
    }

    .container-custom{
        width: 100%;
        // height: 100%;
        display: flex;
        padding-top: 10rem;
        padding-bottom: 10rem;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;

        div {
            display: flex;
            align-items: flex-start;
            color: #fff;
            margin-bottom: 5rem;
            justify-content: space-around;
        }
        h1 {
            font-size: 10rem;
            text-align: left;
            word-spacing: 1;
            color: #fff;
            font-family: sans-serif;
            margin-bottom: 5rem;
        }
        span {
            padding-right: 6rem;
            font-size: 3rem;

        }
        p {
            text-align: left;
            font-family: sans-serif;
            font-size: 3rem;
            font-weight: 400;
            word-spacing: 1;
            padding-right: 1rem;
        }


    }

    &-map{
            width: 40%;
            height: 100%;
            background-color: honeydew;
            display: flex;
            position: relative;
            img{
                top: 0;
                left: 0;
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
    }
}

// responsive==============================================
@media screen and (max-width: 767px) {
    .get-in-touch{
        height:30vh;
    }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
    .get-in-touch{
        height:40vh;    
    }
}

@media screen and (min-width: 993px) and (max-width: 1200px) {
    .get-in-touch{
        height:80vh;
    }
}

// @media screen and (min-width: 1201px) and (max-width: 1599px) {
//     .get-in-touch{
//         height:70vh;
//     }
// }
