@import "react-alice-carousel/lib/scss/alice-carousel.scss";

.hero-section {
    // background-image: url("../../../assets/Image/home-hero.png");
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    

    .slide-1{
        width: 100%;
        height: 100vh;
        // background-color: aqua;
        // background-image: url("../../../assets/Image/home-hero.png");
        background-size: cover;
        background-position: top;
        background-repeat: no-repeat;
    }

    .slide-bg-1{
        background-size: cover;
        background-position: top;
        background-repeat: no-repeat;
        background-image: url("../../../assets/Image/home-hero.png");
        background-position: center center;
    }

    .slide-bg-2{
        background-size: cover;
        background-position: top;
        background-repeat: no-repeat;
        background-image: url('../../../assets/Image/banner-2.png');
        background-position: center center;
    }

    .slide-bg-3 {
        background-size: cover;
        background-position: top;
        background-repeat: no-repeat;
        background-image: url('../../../assets/Image/banner-3.png');
        background-position: center center;
    }

    &-content{
        width:50%;
        padding-left: 15rem;
        position: absolute;
        top: 58%;
        // left: 0;
        transform: translate(0%, -50%);
    }
    .hero-title {
        font-size: 10.5rem;
        font-weight: 300;
        text-align: left;
        color: rgb(255, 255, 255);
        text-transform: uppercase;
        line-height: 1.2;
        display: flex;
        flex-direction: column;

        div{
            transform-origin: center center;
        }
    }

    .alice-carousel__dots{
        position: absolute;
        margin: 0px!important;
        left: 15rem;
        bottom: 50px;
    }
    .alice-carousel__dots-item:not(.__custom) {
        border-radius: 2px;
        
    }

    .alice-carousel__dots-item:not(.__custom).__active {
        background-color: black;
    }
}


@media  screen and (max-width:767px) {

    .hero-section{
        height: 50vh;
        position: relative;
    }
    
    .hero-section .hero-title {
        font-size: 18rem;
    }

    .hero-section-content {
        width: 100%;
        // height: 50vh;
        top: 58%;

    }

   

    .hero-section .slide-1{
        width: 100%;
        height: 50vh;
        // background-color: aqua;
        // background-image: url("../../../assets/Image/home-hero.png");
        background-size: cover;
        background-position: top;
        background-repeat: no-repeat;
    }

    .alice-carousel__dots-item:not(.__custom){
        width: 6px!important;
        height: 6px!important;
    }
}

@media screen and (min-width: 768px) and (max-width: 992px){
    .hero-section-content {
        width: 100%;
    }

    .hero-section .hero-title {
        font-size: 18rem;
    }
}

@media screen and (min-width: 993px) and (max-width: 1200px){
    .hero-section {
        height: 90vh;
        h1{
            line-height: 1.2;
        }
    }
    
}

// @media screen and (min-width: 1201px) and (max-width: 1599px){
//     .hero-section {
//         height: 80vh;

//         h1{
//             line-height: 1.3;
//         }
        
//     }
    
// }