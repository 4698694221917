.bic {
    position: relative;
    padding: 10rem 0;
    &__text {
        max-width: 75%;

        p {
            font-size: 2rem;
            font-weight: 400;
            color: #0d1823;
        }

        h2{
            font-size: 3.4rem;
            margin-bottom: 30px;
            margin-top: 40px;
        }

        h4 {
            font-size: 2.6rem;
            font-weight: 700;
            color: #0d1823;
            padding-bottom: 20px;
            padding-top: 40px;
        }
    }

    .bg1 {
        position: absolute;
        width: 100%;
        max-width: 25%;
        top: 15rem;
    }
}