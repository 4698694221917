.modal {}

.popup {
    .button {
        cursor: pointer;
    }
}

img{
    width: 50%;
    margin: auto;
}

.modal-content {
    padding: 20px;
}

.modal-header{
    padding-bottom: 20px!important;
}

.modal-body {
    form {
        width: 100%;
        padding-top: 10px;
        label {
            font-size: 16px;
            font-weight: 600;
            text-transform: uppercase;
        }
        input{
            width: 100%;
            margin-bottom: 10px;
            font-size: 16px;
            padding: 8px;
            border-radius: 8px;
            box-shadow: none;
            border: 1px solid #727272;
        }

        button {
            background-color: transparent;
            font-size: 16px;
            box-shadow: none;
            padding: 5px 25px!important;
            border: 1px solid black;
            color: black;
            border-radius: 30px;
            margin-top: 10px;
        }

        a {
            background-color: transparent;
            font-size: 16px;
            box-shadow: none;
            padding: 5px 0px!important;
            // border: 1px solid black;
            color: #4D7374;
            // border-radius: 30px;
            margin-top: 10px;
        }
    }
}

.notice{
    padding: 10px 0;
    p{
        font-weight: 600;
        color: #424242;
        font-size: 12px;
    }
}

.success-notice{
    font-size: 16px;
}