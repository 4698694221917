.rera-content{
    margin: 10rem 0;

    h1 {
        font-size: 4rem;
        // margin-bottom: 20px;
    }

    h2{
        font-size: 4rem;
        color: red;
        // margin-bottom: 20px;
    }

    p{
        font-size: 2rem;
        color: #000;
    }

    .rera-title {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 30px;
        .icon {
            margin-right: 15px;
            svg{
                width: 40px;
                height: 40px;
            }
        }

        h1 {
            margin-right: 15px;
        }
    }
}