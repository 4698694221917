.pi {
    &-wrapper{
        display: flex;
        width: 100%;
        min-height: 90vh;
        align-items: center;
    }

    &-left{
        // height: 100%;
        margin-left: 15rem;
        width: 35%;
        display: flex;
        flex-direction: column;
        padding-right: 100px;
    }
    
    &-title {
        padding-bottom: 30px;
        h2 {
            font-size: 6rem;
            color:#4d7374;
            text-transform: uppercase;
        }
    }

    &-content {
        p{
            font-size: 2.6rem;
            padding-bottom: 30px;
        }
    }

    &-download {
        h3{
            color: #4d7374;
            text-transform: uppercase;
        }

    }

    &-right {
        width: 65%;
        img{
            width: 100%;
        }
    }
}

@media (max-width: 600px) {
    .pi-content p { font-size: 5.5rem; }
    .pi-title h2 {font-size: 7rem;}
    .pi-download h3 {font-size: 5rem;}
}