.home-benchmark {
    // height: 100vh;
    margin: 10rem 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .container-custom {
        padding-right: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &-content {
        display: flex;
        // height: 65vh;
        padding: 6rem 0;

       
        &-left {
            width: 55%;
            display: flex;
            height: 100%;
            flex-direction: column;
            align-items: flex-start;
            padding: 4rem 8rem 4rem 8rem;
            justify-content: flex-start;

            &-content {
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;

                &-item1 {
                    margin-bottom: 60px;
                }

                &-item1,
                &-item2 {
                    width: 100%;
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;

                    &-box1,
                    &-box2 {
                        padding: 1rem 2rem;
                        width: 100%;
                        color: rgb(63, 62, 62);

                        div {
                            width: 15%;
                            padding-bottom: 1.5rem;
                        }
                        img {
                            width: 100%;
                            height: auto;
                            object-fit: contain;
                        }
                    }
                    h4 {
                        text-align: left;
                        font-size: 2.7rem;
                        margin-bottom: 1.5rem;
                        margin-top: 1.5rem;
                        font-weight: 600;
                        letter-spacing: 0.6px;
                        color: #0d1823;

                    }
                    p {
                        text-align: left;
                        font-size: 1.8rem;
                        font-weight:500;
                        letter-spacing: 0.4px;
                        color: #0d1823;
                        width: 90%;
                    }
                }
            }
        }

        &-right {
            width: 45%;
            background-image: url("../../../assets/Image/tree1.png");
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            position: relative;
            overflow: hidden;
            transition: all 0.2s ease;

            .home-benchmark-overlay{
                position: absolute;
                left: 0;
                top: 0;
                transform-origin: left;
                width: 100%;
                height: 100%;
                background-color: #fff;
                z-index: 5;
            }

            &-abs{
                position: absolute;
                width: 100%;
                height: 100%;
                background: #0d1823;
                opacity: 0;
                transition: all 0.2s ease;
                // cursor: pointer;
                img {
                    transform: translate(-50%, -50%);
                    top: 50%;
                    left: 50%;
                    width: 40%;
                    position: absolute;
                }

                &:hover {
                    transition: all 0.2s ease;
                    opacity: 1;
                    transition: all 0.2s ease;
                }
            }
        }     
    }
}

// responsive==============================================
@media  screen and (max-width:767px) {

    .home-benchmark-content-left-content-item1-box1 div, 
    .home-benchmark-content-left-content-item1-box2 div, 
    .home-benchmark-content-left-content-item2-box1 div, 
    .home-benchmark-content-left-content-item2-box2 div {
        padding-bottom: 3rem!important;
    }

    .home-benchmark {
        // height: 80vh;
        margin: 20rem 0;
        flex-direction: column;
        padding-right: 15rem;

        &-content {
            display: flex;
            flex-direction: column;
            padding: 10rem 0;
        }
        .home-benchmark-content-left-content-item1 {
            margin-bottom: 20px;
        }

        &-content-right {
            height: 30vh;
        }
        &-content-right , &-content-left {
            // height: 50%;
            width: 100%;
            padding: 0;
            padding-bottom: 10rem;
            h4{
                font-size: 4.5rem;
                font-weight: 600;
            }
            p{
                font-size: 4rem;
            }
        }
    
    }
    
}

@media screen and (min-width: 768px) and (max-width: 992px) {
    .home-benchmark {
        // height: 65vh;
        margin: 20rem 0;
        &-content {
            display: flex;
            // height: 45vh;
            // padding: 10rem 0;
        }

        .home-benchmark-content-left-content-item1{
            margin-bottom: 30px;
        }


    
    }
}

@media screen and (min-width: 993px) and (max-width: 1200px) {

    .home-benchmark {
        // height: 80vh;
        margin: 20rem 0;
        &-content {
            display: flex;
            // height: 60vh;
            // padding: 10rem 0;
        }
    
    }
}

// @media screen and (min-width: 1201px) and (max-width: 1599px) {

//     .home-benchmark {
//         height: 80vh;

//         &-content {
//             display: flex;
//             height: 55vh;
//             padding: 10rem 0;

//             h1{
//                 fonts
//             }
//         }
    
//     }
// }
