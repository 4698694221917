.pjt-hero-section {
    background-color: #f5f5f5;
    display: flex;
    // padding-top: 103px;
    // height: 89vh;
    overflow-x: hidden;
    .container-custom{
        width: 40%; 
        // display: flex;
        justify-content:space-around;
        padding-top: 10rem;
        padding-bottom: 10rem;
    }
    &-left {
        width: 40%;
        display: flex;
        flex-direction: column;

        &-top {
            // height: 25%;
            width: 65%;
            img {
                width: 100%;
                // height: 100%;
            }
        }
        &-middle {
            display: flex;
            width: 70%;
            padding: 7rem 0;
            flex-direction: column;
            div {
                display: flex;
                width: 100%;
                padding-bottom: 3rem;
                align-items: flex-start;
                justify-content: space-between;

                p {
                    font-size: 2.5rem;
                    text-align: left;
                    font-weight: 550;
                    color: rgb(77, 115, 116);
                }
            }
        }
        &-bottom {
            width: 100%;
            padding: 0;
            display: flex;
            justify-content: flex-start;
            h2 {
                text-align: left;
                font-size: 5.5rem;
                font-weight: 550;
                color: rgb(77, 115, 116);
            }
        }
    }

    &-right {
        width: 60%;
        padding-top: 10rem;
        background-color: rgb(77, 115, 116);
        padding-left: 7rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
   
    h1 {
        font-size: 6.5rem;
        text-align: left;
        font-weight: 550;
        color: rgb(255, 253, 253);
    }
    &-bottom {
        height: 75%;
        width: 100%;
        display: flex;
        background-image: url("../../../assets/Image/primeStatus-bg.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        .abs-overlay {
            width: 100%;
            height: 100%;
            position: absolute;
            background-color: #051F27;
            z-index: 1;
        }
    }
}
}

// responsive==============================================

@media  screen and (max-width:767px) {
    .pjt-hero-section{
        // height: 40vh;
    }
}

// @media screen and (max-width: 768px) {
//     .pjt-hero-section{
//         height: 60vh;
//     }
// }

@media screen and (min-width: 768px) and (max-width: 992px) {
    .pjt-hero-section{
        // height: 60vh;
    }
}

@media screen and (min-width: 993px) and (max-width: 1200px) {
    .pjt-hero-section{
        // height: 80vh;
    }
}

// @media screen and (min-width: 1201px) and (max-width: 1599px) {
//     .pjt-hero-section{
//         height: 70vh;
//     }
// }
