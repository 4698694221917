.pc {
    width: 100%;
    img{
        width: 100%;
    }

    iframe{
        width: 100%;
        height: 400px!important;
    }
    div div{width: 50%; float: left; margin-top: 50px; margin-bottom: 50px;}
    .video{
        width: 25%; 
        float: left;
    }
}


@media  screen and (max-width:767px) {
    div div, .video{width: 100% !important; float: none !important; margin-bottom: 50px; }
}
