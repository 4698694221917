input:focus, textarea:focus {
    outline: none;
}
.form-section {
    height: 100vh;
    width: 100%;
    display: flex;
    padding: 4rem 0rem;
    justify-content: center;
    align-items: center;

    &-left {
        width: 50%;
        height: 100%;
        padding: 0 5rem 0 0rem;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;

        h1 {
            padding:0  7rem 0 15rem;
            font-size: 15rem;
            text-align: left;
            line-height: 1;
            font-family: sans-serif;
            word-spacing: 1px;
            letter-spacing: 0px;
            color: rgb(77, 115, 116);
        }
        span {
            height: 15%;
            width: 70%;
            img{
                width: 100%;
                height: 100%;
            }
        }
    }
    &-right {
        width: 50%;
        height: 100%;
        display: flex;

        &-form {
            width: 100%;
            height: 100%;
            padding-top: 5rem;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;

            &-group {
                width: 100%;
                padding-left: 3rem;
                display: flex;
                align-items: center;
                justify-content: flex-start;

                input {
                    width: 100%;
                    font-size: 3rem;
                    border: none;
                    border-bottom: 1px solid rgb(215, 213, 213);
                }
                ::placeholder {
                    /* Chrome, Firefox, Opera, Safari 10.1+ */
                    color: rgb(187, 186, 186);
                    opacity: 1; /* Firefox */
                    font-weight: 300;
                }
                button {
                    padding: 1rem 5rem;
                    font-size: 2.4rem;
                    border: none;
                    background-color: rgb(6, 38, 47);
                    color: white;
                    cursor: pointer;
                }
            }
        }
    }
}


// responsive==============================================
@media screen and (max-width: 767px) {
    .form-section{
        height: auto;
        padding: 20rem 0;
        flex-direction: column;

        &-left{
            width: 100%;

        span{
            display: none;
        }
        }
        &-right{
            width: 100%;
            padding: 10rem 15rem;

            &-form-group{
                padding: 3rem;
            }

            input{
                font-size: 5rem;
                padding: 2rem ;
            }
            button{
                font-size: 5rem;
                padding: 3rem 5rem;
            }
        }

    }
}

@media screen and (min-width: 768px) and (max-width: 992px) {

    .form-section{
        height: 40vh;
    }
}

@media screen and (min-width: 993px) and (max-width: 1200px) {
    .form-section{
        height: 80vh;
    }
}

// @media screen and (min-width: 1201px) and (max-width: 1599px) {
// }
// .form-section{
//     height: 70vh;
// }
