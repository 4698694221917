.green-footer{
    background-color:#4d7374;
    // height: 30vh;
    width: 100%;
    display: flex;
    margin: 0;
    align-items: center;
    position: relative;
    overflow: hidden;
    z-index: 1;
    // padding: 3rem 0;

    .footer-links {
        a {
            display: flex;
            align-items: center;

            p{
                margin-bottom: 0px;
                display: flex;
                align-items: center;

                span {
                    svg{
                        height: 2.5rem;
                    }
                }
            }
        }
    }
  
    &-container{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 33.3%;
        flex: 1;
        height: 100%;

        img{
            width: 80%;
            height: 100%;


        }
        h1{
            text-align: left;
            font-weight: 400;
            font-size: 5rem;
            color: #fff;
            
            
        }
        button{
            margin-left: 7rem;
            background-color: #fff;
            color: #4d7374;
            border: none;
            font-size: 2.3rem;
            line-height: 1.8;
            font-weight: 400;
            padding: 1.5rem 7.5rem 1.2rem;
            transition: all 0.2s ease;
            border: 1px solid #fff;

            &:hover{
                background: transparent;
                border: 1px solid #fff;
                color: #fff;
                transition: all 0.2s ease;
            }
        }
    }
}



.footer{
    background-color: rgb(224,225,226);
    // height: 30vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    position: relative;
    overflow: hidden;
    z-index: 1;
    padding: 8rem 0;
   
   .container-custom{
    display: flex;
   }
    &-container{
        display: flex;
        flex-direction: column;
        // align-items: flex-start;
        justify-content: space-between;
        width: 60%;
        flex: 1;
        // padding-left: 1rem;
        // height: 100%;

        

.logo{
    width: 25%;
    margin-bottom: 2rem;
    
    img{
        width: 100%;
        height: 100%;
    }
}

        div{
            display: flex;
            align-items: center;
            p{
                display: flex;
                justify-content: center;
                padding-right: 2rem;
            }
        }
        h1{
        color: #4d7374;

            text-align: left;
            font-size: 7rem;
            font-weight: 400;
            padding-bottom: 3rem;

            
        }
       p{
        display: flex;
        color: #4d7374;
        justify-content: flex-start;
        font-weight: 500;
        align-items: flex-start;
        font-size: 2rem;
        text-align: left;
        

        span{
            padding-right: 0.8rem;
            font-size: 2rem;
        }
        
       }
    }

    &-container-2 {
        width: 40%;
        h1{
            color: #4d7374;
            text-align: left;
            font-size: 5rem;
            font-weight: 400;
            padding-bottom: 5rem;  
            line-height: 1;   
        }
       p{
        display: flex;
        color: #4d7374;
        justify-content: flex-start;
        font-weight: 500;
        align-items: flex-start;
        font-size: 1.8rem;
        text-align: left;
        

        span{
            padding-right: 2rem;
            font-size: 1.8rem;
        }
        
       }
    }

}

// responsive==============================================
@media screen and (max-width: 768px) {
    .green-footer{
        // height: 20vh;
    }

    .footer{
        // height: 20vh;
         padding: 15rem 0;
    }

    .footer-container div p {
        font-size: 3.5rem;
    }

    .footer-container p span{
        font-size: 3.5rem;
        margin-right: 2px;
    }

    .footer-container-2 h1 {
        font-size: 8rem;
    }

    .footer-container-2 p{
        font-size: 3.5rem;
    }

    .footer-container-2 p span{
        font-size: 3.5rem;
    }
}

@media screen and (min-width: 769px) and (max-width: 992px) {
}

@media screen and (min-width: 993px) and (max-width: 1200px) {
}

@media screen and (min-width: 1201px) and (max-width: 1599px) {
}
