.blogs-main {
    padding: 10rem 0;

    &-wrapper{
        .row{
            margin-bottom: 12px;
        }
    }

    .blog-card {
        border: 1px solid rgb(228, 228, 228);
        .blog-image{
            height: 400px;

            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        
    }

    .blog-content {
        padding: 0 20px 30px;

        .blog-caption {
            margin: 15px 0 10px;
            p{
                color: #6f707a;
                font-size: 1.4rem;
                margin-bottom: 0px;
            }
        }
        .blog-title {
            margin: 0 0 10px 0;
            h4{
                font-size: 3.5rem;
                color: #0f1121;
                margin-bottom: 0px;
            }
        }

        .blog-title-text{
            width: 80%;
            p{
                color: #000;
                font-size: 2rem;
                margin-bottom: 0px;
            }
        }
    }
    
}


@media  screen and (max-width:767px) {
    .blogs-main .blog-card .blog-image {
        height: 300px;
    }
    
}

@media screen and (min-width: 768px) and (max-width: 992px) {
   
}

@media screen and (min-width: 993px) and (max-width: 1200px) {

   
}
