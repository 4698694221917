.bi-hero {
    padding: 15rem 0;
    .info-box{
        width: 100%;
        padding: 0 150px 0 0;
        &__content{
            display: flex;
            justify-content: end;
            align-items: center;

            &-text {
                margin-right: 20px;
               p{
                font-weight: 600;
                text-transform: capitalize;
                color: #000;
                font-size: 14px;
                margin-bottom: 0px;
               } 

               span{
                font-size: 14px;
                color: #6f707a;
               }
            }

            &-image {
                max-width: 60px;
                img{
                    width: 100%
                }
            }
        }
    }

    .bi-title {
        max-width: 90%;
        h2 {
            color: #4d7374;
            font-size: 7.5rem;
            font-weight: 400;
            line-height: 1;
        }
    }

    .bi-caption{
        p{
            text-transform: uppercase;
            font-size: 14px;
            color: #000;
            margin: 40px 0;
            font-weight: 700;
        }
    }

    .bi-intro{
        max-width: 75%;
        p{
            font-size: 1.8rem;
            font-weight: 400;
            color: #0d1823;
        }
    }
}