.our-goals{
    background-color: rgb(255, 255, 255);
    // height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 10rem 0rem;

    .container-custom{
        display: flex;
        flex-direction: column;
        padding: 0;
        justify-content: center;
        overflow: hidden;
    }
    h1{
        padding-left: 15rem;
        padding-bottom: 10rem;
    }

    &-content {
        display: flex;
        // height: 70vh;
        padding: 0;
        width: 100%;

        &-left {
            width: 10%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 4rem 0;

            // div{
            //     width: 100%;
            //     height: 80%;
            //     background-image: url("../../../assets/Image/about-pattern2.png");
            //     background-repeat: no-repeat;
            //     background-size: contain;
            // }
        }
        &-middle {
            width: 50%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;

            &-1,&-2,&-3,&-4{
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                padding-bottom: 7rem;

                span{
                    width: 40%;
                    font-size: 5rem;
                    font-weight: 500;
                    text-align: left;
                    padding-left: 7rem;
                    color: rgb(77, 115, 116);
                    text-transform: uppercase;
                    line-height: 1.5;
                }

                div{
                    width: 60%;
                    display: flex;
                    padding: 0 10rem 0 1em;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;
                }

                h1{
                    font-size: 5rem;
                    font-weight: 400;
                    text-align: left;
                    padding:0;
                    margin: 0;
                    color: rgb(77, 115, 116);
                    text-transform: uppercase;
                    line-height: 1.2;
                    font-family: sans-serif;
                }

                p{
                    font-size: 2rem;
                    letter-spacing: normal;
                    line-height: 1.2;
                    margin: 0;
                    font-weight: 500;
                    padding: 0;
                    text-align: left;
                }
            }
        }

        &-right {
            width: 40%;
            position: relative;
            img{
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                object-fit: cover;
                width: 100%;
            }
        }
    }
}

// responsive==============================================
@media screen and (max-width: 767px) {
    .our-goals{
        height: auto;
        padding: 20rem 0;

        &-content{
            flex-direction: column;

            &-left{
                display: none;
            }
            &-right{
                // width: 100%;
                width: 100%;
                position: relative;
                height: 35vh;
                img{
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    object-fit: cover;
                    width: 100%;
                }
                // height: 40vh;
            }
            &-middle{
                padding: 0 15rem;
                width: 100%;
                span{
                    font-size: 6rem;
                }
                h1{
                    font-size: 6rem;
                }
                p{
                    font-size: 4rem;
                }
            }
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
    .our-goals{
        // height: 80vh;
    }
    .our-goals-content-right{
        display: flex;
        align-items: center;
        background-position: center;
    }
}

@media screen and (min-width: 993px) and (max-width: 1200px) {
    .our-goals{
        // height: 90vh;
    }
    .our-goals-content-right{
        display: flex;
        align-items: center;
        background-position: center;
    }
}

// @media screen and (min-width: 1201px) and (max-width: 1599px) {
//     .our-goals{
//         height: 80vh;
//     }
//     .our-goals-content-right{
//         display: flex;
//         align-items: center;
//         background-position: center;
//     }
// }
