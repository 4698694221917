.container-custom {
    width: 100%;
    padding: 0 15rem;
    margin: 0 auto;
}

@media  screen and (max-width:767px) {

    .container-custom {
        width: 100%;
        padding: 0 20px;
        margin: 0 auto;
    }
    
}

@media screen and (min-width: 768px) and (max-width: 992px) {

}

@media screen and (min-width: 993px) and (max-width: 1200px) {

}

@media screen and (min-width: 1201px)  {
  
}
