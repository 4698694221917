.blog-hero {
    position: relative;
    width: 100%;
    background-image: url('../../assets/Image/contactbg.jpg');
        background-repeat: no-repeat;
        background-size: cover;
    
    &-content {
        
        height: 90vh;
        width: 100%;
        display: flex;
        justify-content: start;
        align-items: center;
    }

    .blog-main-title {
        h2{
            font-weight: 400;
            font-size: 12rem;
            line-height: 1;
            text-transform: uppercase;
            color: #fff;
        }
    }
}