.vision-mission{
    background-color: rgb(255, 255, 255);
    // height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 1rem;
    margin: 0;
    position: relative;
    overflow: hidden;
    z-index: 1;
    padding: 20rem 0;

    .container-custom{
        display: flex;
    }

    .content1 {
        border-left: 1px solid grey;
    }

    .content2{
        border-left: 1px solid grey;
        border-right: 1px solid grey;
    }

&-content1 {
        width: 50%;
        // height: 55vh;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0rem 12rem;
        justify-content: space-around;
        

        span{
            width:15%;
            margin-bottom: 20px;
            img{
                width: 100%;
                // height: 100%;
            }
        }

        p {
            font-size: 2.3rem;
            padding-bottom: 0;
            font-family: sans-serif;
            letter-spacing: normal;
            line-height: 1.3;
            margin: 0;
            text-align: left;
            margin-bottom: 15px;
        }

        h1 {
            text-align: left;
            font-family: sans-serif;
            padding: 0%;
            margin: 0;
            font-weight: 400;
            color: rgb(77, 115, 116);
            font-size: 5rem;
            margin-bottom: 20px;
        }

        .pre {
            color: rgb(6, 6, 6);
            text-decoration: none;
            font-size: 1.7rem;
            font-weight: 600;
            letter-spacing: 0.6px;
            text-align: left;
            font-family: sans-serif;
            margin-bottom: 15px;
        }
    }
}

// responsive==============================================
@media screen and (max-width: 767px) {
    .vision-mission{
        height: auto;
        padding: 20rem 0;

        
        
        .container-custom{
            flex-direction: column;
        }

        &-content1{
            // height: 25vh;
            width: 100%;

            .pre{
                font-size: 2.9rem;
            }
            p{
                font-size: 4rem;
            }
        }
    }

    .vision-mission-content1 span, .vision-mission-content1 h1, .vision-mission-content1 .pre, .vision-mission-content1 p {
        margin-bottom: 10px;
    }


    .vision-mission-content1 .pre {
        font-size: 3.5rem;
    }

    .vision-mission-content1 p{
        font-size: 4rem;

    }

    
    
}

@media screen and (min-width: 768px) and (max-width: 992px) {
    .vision-mission{
        // height: 60vh;

        &-content1{
            // height: 35vh;

            .pre{
                font-size: 2rem;
            }
            p{
                font-size: 2.9rem;
            }
        }
    }

    
}

@media screen and (min-width: 993px) and (max-width: 1200px) {
    .vision-mission{
        // height: 80vh;

        &-content1{
            // height: 45vh;

            .pre{
                font-size: 1.9rem;
            }
            p{
                font-size: 2.9rem;
            }
        }
    }
}

// @media screen and (min-width: 1201px) and (max-width: 1599px) {
//     .vision-mission{
//         height: 80vh;

//         &-content1{
//             height: 45vh;

//             .pre{
//                 font-size: 1.9rem;
//             }
//             p{
//                 font-size: 2.9rem;
//             }
//         }
//     }
// }
