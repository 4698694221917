.offcanvas.offcanvas-end {
    background-color: rgb(5, 31, 39);
}

.offcanvas-body{
    display: flex;
    align-items: center;
    padding-left: 20px!important;
}

.bg-img-wrapper {
    width: 20%;
    margin-left: auto;
    img{
        width: 100%;
    }
}

.close{
    margin: 10px auto 0;
    width: 4rem;
    height: 4rem;
    .btn-close {
        background-size: cover;
    }
}

#dropdown-basic {
    background: transparent;
    border: none;
}

.navbar-nav .dropdown-menu {
    background: transparent;
    // padding-left: px;
}

.btn-check:focus + .btn, .btn:focus {
    box-shadow: none!important;
}

.dropdown-item:focus, .dropdown-item:hover {
    background: transparent!important;
    border: none!important;
    box-shadow: none!important;
}

.dropdown-toggle::after {
    border-top: 1em solid!important;
    border-right: 1em solid transparent!important;
    border-left: 1em solid transparent!important;
}



.nav-a {
    color: white;
    font-size: 4rem;
    text-transform: uppercase;
    font-weight: 300!important;
    margin-bottom: 10px;
    a {
        font-weight: 300!important;
    }
    &:hover{
        color: white;
    }
}

.header {
    background-color: rgb(5, 31, 39);
    padding: 10px 0;
    // position: absolute;
    top: 0;
    width: 100%;
    z-index: 5;
    display: flex;
    justify-content: space-between;

    a{
        color: white;

        &:hover {
            color: white;
        }
    }

   
    &-logo {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding-left: 15rem;

        div {
            width: 24%;
            // height: 43%;

            img {
                width: 100%;
                // height: 100%;
            }
        }
    }
    &-container {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        align-items: center;

        hr {
            width: 100%;
            opacity: 1;
            color: rgb(255, 255, 255);
        }
        &-content {
            display: flex;
            width: 100%;
            padding: 1.8rem 0 0 0 ;

            &-left {
                width: 50%;
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                p {
                    font-size: 2rem;
                    text-align: left;
                    font-family: "Assistant Regular", sans-serif;
                    color: rgb(255, 253, 253);

                    &:hover {

                        .link-hover {
                            margin-left: 7px;
                            transition: all 0.2s ease;
                        }
                       
                    }

                    .link-hover {
                        transition: all 0.2s ease;
                        
                    }
                }
            }
            &-right {
                width: 50%;
            }
        }
    }
}
#container {
    display: flex;
    padding-right: 7rem;
    justify-content: flex-end;

    #toggle {
        .navbar-toggler-icon {
            width: 3em !important;
            height: 3em !important;
            background-image: url("../../assets/Image/menu.png");
        }
       
    }
    
}

// responsive==============================================
@media  screen and (max-width:767px) {
    .close{
        margin: 10px auto 0;
        width: 12rem;
        height: 12rem;
        .btn-close {
            background-size: cover;
        }
    }

    .header{
        &-logo{
            div{
                width: 35%;
                height: 43%;
            }
        }
        .header-container-content-left p{
            font-size: 3rem;
        }
        
    }
    .nav-a{
        font-size: 10rem;
        text-decoration: none;
    }
    .close{
        font-size: 7rem;
        padding: 4rem;
    }
    #container {
        display: flex;
        padding-right: 7rem;
        justify-content: flex-end;
       
    
        #toggle {
            .navbar-toggler-icon {
                width: 6em !important;
                height: 6em !important;
                background-image: url("../../assets/Image/menu.png");
            }
           
        }
        
    }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
    
}

@media screen and (min-width: 993px) and (max-width: 1200px) {
}

@media screen and (min-width: 1201px) and (max-width: 1599px) {
}
